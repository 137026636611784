import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import {User} from "../../app/services/auth";



type AuthState = {
    user: User | null
    token: string | null
    isLoggedIn: boolean
}

const slice = createSlice({
    name: 'auth',
    initialState: { user: null, token: null, isLoggedIn:false } as AuthState,
    reducers: {
        setCredentials: (
            state,
            { payload: { user, token } }: PayloadAction<{ user: User; token: string }>
        ) => {
            console.log("setting credentilas success");
            state.user = user
            state.token = token
            state.isLoggedIn = true
        },
        clearCredentials: (
            state
        ) => {
            state.user = null
            state.token = null
            state.isLoggedIn = false
        },
    },
})

export const { setCredentials, clearCredentials } = slice.actions

export default slice.reducer

export const selectCurrentUser = (state: RootState) => state.auth.user

import React from 'react';
import {
    Box, Button, Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    InputGroup, InputLeftElement, Link,
    Stack,
    Textarea,
    Text,
    useColorModeValue, VStack
} from "@chakra-ui/react";
import {FaEnvelope, FaUser} from "react-icons/fa";
import {Link as RouteLink} from "react-router-dom";

function Contact() {
    return (
        <ContactFormWithSocialButtons></ContactFormWithSocialButtons>
    );
}

export default Contact;


function ContactFormWithSocialButtons() {
    //const { hasCopied, onCopy } = useClipboard('example@example.com');

    return (
        <Flex
            minH={'500px'}
            align={'center'}
            justify={'center'}
            bg={useColorModeValue('gray.50', 'gray.800')}>
            <Stack spacing={8} mx={'auto'} maxW={'3xl'} py={12} px={6}>
                <Heading fontSize={'4xl'} textAlign={'center'}>
                    Contact
                </Heading>

                <VStack spacing={8}>
                    <Text textAlign={'justify'}>
                        Veuillez noter que je ne prends pas de commande pour le moment, je ne vends que des patrons de couture via ce site internet.
                    </Text>

                    <Text textAlign={'justify'}>
                        Il est possible de payer par carte bancaire sans créer de compte, toutefois pour un meilleur suivi je vous conseille d'avoir créé un compte. Les paiements par Paypal ne sont pour l'instant pas acceptés. Merci de votre compréhension.
                    </Text>

                    <Text textAlign={'justify'}>
                        N’hésitez pas à demander de l’aide ou des conseils en remplissant le fomulaire.
                    </Text>

                    <Text textAlign={'justify'}>
                        Veuillez vous reporter aux <Link as={RouteLink} color={'pink.400'} to={"/conditions-generales-de-vente"}>conditions générales de vente</Link> et à la <Link as={RouteLink} color={'pink.400'} to={"/politique-de-confidentialite"}>politique de confidentialité</Link> avant de remplir ce formulaire, vous pourrez y trouver la réponse que vous cherchez.
                    </Text>
                </VStack>
                    <Box
                        bg={useColorModeValue('white', 'gray.700')}
                        borderRadius="lg"
                        p={8}
                        color={useColorModeValue('gray.700', 'whiteAlpha.900')}
                        shadow="base">
                        <VStack spacing={5}>
                            <FormControl isRequired>
                                <FormLabel>Nom/Prénom</FormLabel>

                                <InputGroup>
                                    <InputLeftElement children={<FaUser />} />
                                    <Input type="text" name="name" placeholder="Your Name" />
                                </InputGroup>
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel>Email</FormLabel>

                                <InputGroup>
                                    <InputLeftElement children={<FaEnvelope />} />
                                    <Input
                                        type="email"
                                        name="email"
                                        placeholder="Votre Email"
                                    />
                                </InputGroup>
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel>Message</FormLabel>

                                <Textarea
                                    name="message"
                                    placeholder="Votre message"
                                    rows={6}
                                    resize="vertical"
                                />
                            </FormControl>

                            <Button
                                colorScheme="pink"
                                bg="pink.400"
                                color="white"
                                _hover={{
                                    bg: 'pink.500',
                                }}
                                isFullWidth>
                                Envoyer
                            </Button>
                        </VStack>
                    </Box>
            </Stack>
        </Flex>
    );
}

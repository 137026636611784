import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Image,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useDisclosure, Center,
} from '@chakra-ui/react';
import {ChevronDownIcon, ChevronRightIcon, CloseIcon, HamburgerIcon} from "@chakra-ui/icons";

import {
    Link as RouteLink
} from "react-router-dom";
import {useEffect, useState} from "react";

export default function WithSubnavigation() {
    const { isOpen, onToggle } = useDisclosure();

    const [email, setEmail] = useState('');


    useEffect(() => {
        if(window.Snipcart){
            window.Snipcart!.events.on('snipcart.initialized', () => {
                setEmail(window.Snipcart.store.getState().customer.email);
            });

            window.Snipcart!.events.on('customer.signedin', (customer:{email:string}) => {
                setEmail(customer.email);
            });

            window.Snipcart!.events.on('customer.signedout', () => {
                setEmail('');
            });
        }
    }, [])

    return (
        <Box>
            <Flex
                bg={useColorModeValue('white', 'gray.800')}
                color={useColorModeValue('gray.600', 'white')}
                minH={'60px'}
                py={{ base: 2 }}
                px={{ base: 4 }}
                borderBottom={1}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'gray.900')}
                align={'center'}>
                <Flex
                    flex={{ base: 1, md: 'auto' }}
                    ml={{ base: -2 }}
                    display={{ base: 'flex', md: 'none' }}>
                    <IconButton
                        onClick={onToggle}
                        icon={
                            isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
                        }
                        variant={'ghost'}
                        aria-label={'Toggle Navigation'}
                    />
                </Flex>
                <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
                    <Link as={RouteLink} to="/">
                        <Image src='/plush-toy-64x64.png' alt='Doudie' />
                    </Link>
                    <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
                        <Center>
                            <DesktopNav />
                        </Center>
                    </Flex>
                </Flex>

                <Stack
                    flex={{base: 1, md: 0}}
                    justify={'flex-end'}
                    direction={'row'}
                    spacing={6}>
                    <Button
                        fontSize={'sm'}
                        fontWeight={600}
                        color={'white'}
                        bg={'pink.400'}
                        className={"snipcart-customer-signin"}
                        _hover={{
                            bg: 'pink.300',
                        }}>
                        {email ? 'Mon compte' : 'Connexion' }
                    </Button>
                </Stack>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNav />
            </Collapse>
        </Box>
    );
}

const DesktopNav = () => {
    const linkColor = useColorModeValue('gray.600', 'gray.200');
    const linkHoverColor = useColorModeValue('gray.800', 'white');
    const popoverContentBgColor = useColorModeValue('white', 'gray.800');

    return (
        <Stack direction={'row'} spacing={4}>
            {NAV_ITEMS.map((navItem) => (
                <Box key={navItem.label}>
                    <Popover trigger={'hover'} placement={'bottom-start'}>
                        <PopoverTrigger>
                            <Link
                                as={RouteLink}
                                to={navItem.href ?? '#'}
                                p={2}
                                href={navItem.href ?? '#'}
                                fontSize={'sm'}
                                fontWeight={500}
                                color={linkColor}
                                className={navItem.class}
                                _hover={{
                                    textDecoration: 'none',
                                    color: linkHoverColor,
                                }}>
                                {navItem.label}

                            </Link>
                        </PopoverTrigger>

                        {navItem.children && (
                            <PopoverContent
                                border={0}
                                boxShadow={'xl'}
                                bg={popoverContentBgColor}
                                p={4}
                                rounded={'xl'}
                                minW={'sm'}>
                                <Stack>
                                    {navItem.children.map((child) => (
                                        <DesktopSubNav key={child.label} {...child} />
                                    ))}
                                </Stack>
                            </PopoverContent>
                        )}
                    </Popover>
                </Box>
            ))}
        </Stack>
    );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
    return (
        <Link
            as={RouteLink}
            to={href!}
            href={href}
            role={'group'}
            display={'block'}
            p={2}
            rounded={'md'}
            _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
            <Stack direction={'row'} align={'center'}>
                <Box>
                    <Text
                        transition={'all .3s ease'}
                        _groupHover={{ color: 'pink.400' }}
                        fontWeight={500}>
                        {label}
                    </Text>
                    <Text fontSize={'sm'}>{subLabel}</Text>
                </Box>
                <Flex
                    transition={'all .3s ease'}
                    transform={'translateX(-10px)'}
                    opacity={0}
                    _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
                    justify={'flex-end'}
                    align={'center'}
                    flex={1}>
                    <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
                </Flex>
            </Stack>
        </Link>
    );
};

const MobileNav = () => {
    return (
        <Stack
            bg={useColorModeValue('white', 'gray.800')}
            p={4}
            display={{ md: 'none' }}>
            {NAV_ITEMS.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
        </Stack>
    );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                as={RouteLink}
                to={href ?? '#'}
                justify={'space-between'}
                align={'center'}
                _hover={{
                    textDecoration: 'none',
                }}>
                <Text
                    fontWeight={600}
                    color={useColorModeValue('gray.600', 'gray.200')}>
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={'all .25s ease-in-out'}
                        transform={isOpen ? 'rotate(180deg)' : ''}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={'solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    align={'start'}>
                    {children &&
                    children.map((child) => (
                        <Link
                            as={RouteLink}
                            key={child.label} py={2} to={child.href ?? '#'}>
                            {child.label}
                            class={child.class}
                        </Link>
                    ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};

interface NavItem {
    label: string;
    subLabel?: string;
    children?: Array<NavItem>;
    class?: string;
    href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
    {
        label: 'Patrons',
        children: [
            {
                label: 'Doudous',
                subLabel: 'Ici tout est fait main',
                href: '/products?type=patron',
            },
        ],
    },
    {
        label: 'Petite enfance',
        children: [
            {
                label: 'Doudous',
                href: '/products?type=doudou',
            },
            {
                label: 'Accessoires de naissance',
                href: '/products?type=naissance',
            },
        ],
    },

    {
        label: 'Écologie',
        href: '/products?type=ecologie',
    },
    {
        label: 'Diabète',
        href: '/products?type=diabete',
    },
    {
        label: 'Panier',
        href: undefined,
        class: 'snipcart-checkout'
    },
];


// import React from 'react';
// import {useAppSelector} from "../../app/hooks";
//
// function Header() {
//
//     const { user } = useAppSelector((state) => state.auth);
//
//     return (
//         <div>Header {JSON.stringify(user)}</div>
//     );
// }
//
// export default Header;

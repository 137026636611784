import React from 'react';
import {useGetAllQuery} from "../../app/services/products";
import {Container, Grid, Skeleton} from "@chakra-ui/react";
import ProductAddToCart from "./ProductCard";

function ListProducts() {
    const { data = [], isLoading, isFetching, isError } = useGetAllQuery();

    if (isError) return <div>An error has occurred!</div>

    if (isLoading || isFetching) return <Skeleton />

    return (
        <Container maxW={'8xl'} mt={10}>
        <Grid
            w="full"
            gridGap="5"
            gridTemplateColumns="repeat( auto-fit, minmax(300px, 1fr) )"
        >
            {data.map((produit:any) => (
                <ProductAddToCart key={produit.id} {...produit} />
            ))}
        </Grid>
        </Container>

        // <Container maxW={'8xl'} mt={10}>
        //     <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
        //         {data.map((produit:any) => (
        //             <ProductAddToCart {...produit}/>
        //         ))}
        //     </SimpleGrid>
        // </Container>
    );
}

export default ListProducts;

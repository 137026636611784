import {
    Flex,
    Box,
    Image,
    Badge,
    useColorModeValue,
    Icon,
    chakra,
    Tooltip, Link,
} from '@chakra-ui/react';
import { FiShoppingCart } from 'react-icons/fi';
import {IProduct} from "../../app/services/products";
import {Link as RouteLink} from "react-router-dom";

const data = {
    isNew: true,
    imageURL:
        'https://images.unsplash.com/photo-1572635196237-14b3f281503f?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=4600&q=80',
    name: 'Wayfarer Classic un walou long',
    price: 4.5,
    rating: 4.2,
    numReviews: 34,
};

function ProductAddToCart(props:IProduct) {
    return (
        <Flex p={10} w="full" alignItems="center" justifyContent="center">
            <Box
                bg={useColorModeValue('white', 'gray.800')}
                maxW="sm"
                minW="300px" //Must match minmax value in parent's grid
                borderWidth="1px"
                rounded="md"
                shadow="lg"
                position="relative">

                <Link as={RouteLink} to={props.id}>
                    <Image
                        src={props.imageUrl}
                        alt={`Picture of ${data.name}`}
                        roundedTop="md"
                    />
                </Link>

                <Box p="6">
                    <Box d="flex" alignItems="baseline">
                        {
                            props.tags?.map(tag => (
                                <Badge rounded="full" px="2" mr="2" fontSize="0.8em" colorScheme="red">
                                    {tag}
                                </Badge>
                            ))
                        }
                    </Box>
                    <Flex mt="1" justifyContent="space-between" alignContent="center">
                        <Box
                            fontSize="2xl"
                            fontWeight="semibold"
                            as="h4"
                            lineHeight="tight"
                            isTruncated>
                            {props.name}
                        </Box>

                    </Flex>

                    <Flex justifyContent="space-between" alignContent="center">
                        {/*<Rating rating={data.rating} numReviews={data.numReviews} />*/}
                        <Box fontSize="2xl" color={useColorModeValue('gray.800', 'white')}>
                            {props.price.toFixed(2)}
                            <Box as="span" color={'gray.600'} fontSize="lg">
                                €
                            </Box>
                        </Box>
                        <Tooltip
                            hasArrow
                            label="Ajouter au panier"
                            bg="white"
                            placement={'top'}
                            color={'gray.800'}
                            fontSize={'1.2em'}>
                            <chakra.a
                                className="snipcart-add-item"
                                data-item-id={props.id}
                                data-item-image={props.imageUrl}
                                data-item-name={props.name}
                                data-item-price={props.price}
                                // onClick={() => {
                                // addItem(props);
                                // toast({
                                //     position:"top",
                                //     status: 'success',
                                //     title: `${props.name} a été ajouté au panier`,
                                //     isClosable: true,
                                // })
                                // } }
                                display={'flex'}>
                                <Icon as={FiShoppingCart} h={7} w={7} alignSelf={'center'} />
                            </chakra.a>
                        </Tooltip>
                    </Flex>
                </Box>
            </Box>
        </Flex>
    );
}

export default ProductAddToCart;

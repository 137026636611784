import React from 'react';
import './App.css';
import Header from "./features/header/Header";
import Home from "./components/home/Home";
import {CartProvider} from "react-use-cart";
import {useRoutes} from "react-router-dom";
import Footer from "./components/footer/Footer";
import ListProducts from "./features/products/ListProducts";
import {Box} from "@chakra-ui/react";
import Contact from "./components/contact/Contact";
import ConditionsGeneralesDeVente from "./components/contact/ConditionsGeneralesDeVente";
import PolitiqueDeConfidentialite from "./components/contact/PolitiqueDeConfidentialite";
import ProductDetail from "./features/products/ProductDetail";

const routes = () => [
  { path: "/", element: <Home /> },
  { path: "/home", element: <Home /> },
  { path: "/products", element: <ListProducts /> },
  { path: "/products/*", element: <ListProducts /> },
  { path: "/products/:id", element: <ProductDetail /> },
  { path: "/contact", element: <Contact /> },
  { path: "/conditions-generales-de-vente", element: <ConditionsGeneralesDeVente /> },
  { path: "/politique-de-confidentialite", element: <PolitiqueDeConfidentialite /> },
];

function App() {
  const routing = useRoutes(routes());

  return (
    <div className="App">
      <CartProvider>
        <Box minH={"calc(100vh - 201px)"} className={"fit-me"}>

        <Header />
          {routing}
        </Box>
        <Footer />
      </CartProvider>
    </div>
  );
}

export default App;

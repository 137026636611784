import React from 'react';
import {Flex, Stack, useBreakpointValue, VStack, Text} from "@chakra-ui/react";

function Home() {
    return (
        <WithBackgroundImage></WithBackgroundImage>
        // <div>
        //     <section className="section-accueil">
        //         <div className="">
        //             <h1 className="shadow-h1 text-white">Bienvenue sur le site de Doudie ! </h1>
        //             <h2 className="shadow-h1 text-white">Venez découvrir nos patrons de couture ainsi que nos doudous tout doux</h2>
        //         </div>
        //     </section>
        // </div>
    );
}

export default Home;

export function WithBackgroundImage() {
    return (
        <Flex
            w={'full'}
            h={'calc(100vh - 81px)'}
            backgroundImage={
                'url(https://images.unsplash.com/photo-1573297663823-b4d453566b32?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80)'
            }
            backgroundSize={'cover'}
            backgroundPosition={'center center'}>
            <VStack
                w={'full'}
                justify={'center'}
                px={useBreakpointValue({ base: 4, md: 8 })}
                bgGradient={'linear(to-r, blackAlpha.600, transparent)'}>
                <Stack maxW={'2xl'} align={'flex-start'} spacing={6}>
                    <Text
                        color={'white'}
                        fontWeight={700}
                        lineHeight={1.2}
                        fontSize={useBreakpointValue({ base: '3xl', md: '4xl' })}>
                        Bienvenue sur le site de Doudie ! Venez découvrir nos patrons de couture ainsi que nos doudous tout doux, c'est fait main
                    </Text>
                </Stack>
            </VStack>
        </Flex>
    );
}

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export interface User {
    username: string,
    email: string,
    password: string,
    status: string,
    confirmationCode: string,
    accessToken: string
}

// export interface UserResponse {
//     user: User
//     token: string
// }

export interface LoginRequest {
    username: string
    password: string
}

export const api = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/auth/',
        // prepareHeaders: (headers, { getState }) => {
        //     // By default, if we have a token in the store, let's use that for authenticated requests
        //     const token = (getState() as RootState).auth.token
        //     if (token) {
        //         headers.set('authorization', `Bearer ${token}`)
        //     }
        //     return headers
        // },
    }),
    endpoints: (builder) => ({
        login: builder.mutation<User, LoginRequest>({
            query: (credentials) => ({
                url: 'signin',
                method: 'POST',
                body: credentials,
            }),
        }),
        protected: builder.mutation<{ message: string }, void>({
            query: () => 'protected',
        }),
    }),
})

export const { useLoginMutation, useProtectedMutation } = api

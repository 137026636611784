import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {Item} from "react-use-cart";

export interface IProduct extends Item {
    name?: string;
    description?: string;
    tags?: string[];
    imageUrl?: string;
    thumbnailUrl? : string,

}

// export interface UserResponse {
//     user: User
//     token: string
// }

export interface LoginRequest {
    username: string
    password: string
}

export const productApi = createApi({
    reducerPath: 'productApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://doudie.blob.core.windows.net/public',
        // prepareHeaders: (headers, { getState }) => {
        //     // By default, if we have a token in the store, let's use that for authenticated requests
        //     const token = (getState() as RootState).auth.token
        //     if (token) {
        //         headers.set('authorization', `Bearer ${token}`)
        //     }
        //     return headers
        // },
    }),
    endpoints: (builder) => ({
        // login: builder.mutation<User, LoginRequest>({
        //     query: (credentials) => ({
        //         url: 'signin',
        //         method: 'POST',
        //         body: credentials,
        //     }),
        // }),
        getAll: builder.query<IProduct, void>({
            query: () => '/product_list.json',
        }),
    }),
})

export const { useGetAllQuery } = productApi
